import React from "react";

const CustomerForm = () => {
  return(
    <div dangerouslySetInnerHTML={{
      __html: `
	  <!-- Note :
   - You can modify the font style and form style to suit your website. 
   - Code lines with comments Do not remove this code are required for the form to work properly, make sure that you do not remove these lines of code. 
   - The Mandatory check script can modified as to suit your business needs. 
   - It is important that you test the modified form before going live.-->
<div id='crmWebToEntityForm' class='zcwf_lblLeft crmWebToEntityForm' style='background-color: white;color: black;max-width: 600px;'>
  <meta name='viewport' content='width=device-width, initial-scale=1.0'>
   <META HTTP-EQUIV ='content-type' CONTENT='text/html;charset=UTF-8'>
   <form action='https://crm.zoho.com/crm/WebToContactForm' name=WebToContacts4501926000086354115 method='POST' onSubmit='javascript:document.charset="UTF-8"; return checkMandatory4501926000086354115()' accept-charset='UTF-8'>
 <input type='text' style='display:none;' name='xnQsjsdp' value='576136f6aa4834cb4dd62dd1423e91bc664b47120f48e2939f823fc19530195f'></input> 
 <input type='hidden' name='zc_gad' id='zc_gad' value=''></input>
 <input type='text' style='display:none;' name='xmIwtLD' value='2811c20190b0f9705b46a0ae4b5b2b4dde7ea5a64aefb6a682884df86802e0e0'></input> 
 <input type='text'  style='display:none;' name='actionType' value='Q29udGFjdHM='></input>
 <input type='text' style='display:none;' name='returnURL' value='https&#x3a;&#x2f;&#x2f;coast-to-coastcarports.com&#x2f;thank-you-contact-us' > </input>
	 <!-- Do not remove this code. -->
<style>
html,body{
	margin: 0px;
}
#crmWebToEntityForm.zcwf_lblLeft {
	width:100%;
	padding: 25px;
	margin: 0 auto;
	box-sizing: border-box;
}
#crmWebToEntityForm.zcwf_lblLeft * {
	box-sizing: border-box;
}
#crmWebToEntityForm{text-align: left;}
#crmWebToEntityForm * {
	direction: ltr;
}
.zcwf_lblLeft .zcwf_title {
	word-wrap: break-word;
	padding: 0px 6px 10px;
	font-weight:bold }.zcwf_lblLeft.cpT_primaryBtn:hover{
	background: linear-gradient(#02acff 0,#006be4 100%) no-repeat padding-box !important;
	box-shadow: 0 -2px 0 0 #0159b9 inset !important;
	border: 0 !important;
	color: #fff !important;
	outline: 0 !important;
}.zcwf_lblLeft .zcwf_col_fld input[type=text], input[type=password], .zcwf_lblLeft .zcwf_col_fld textarea {
	width: 60%;
	border: 1px solid #c0c6cc !important;
	resize: vertical;
	border-radius: 2px;
	float: left;
}
.zcwf_lblLeft .zcwf_col_lab {
	width: 30%;
	word-break: break-word;
	padding: 0px 6px 0px;
	margin-right: 10px;
	margin-top: 5px;
	float: left;
	min-height: 1px;
}
.zcwf_lblLeft .zcwf_col_fld {
	float: left;
	width: 68%;
	padding: 0px 6px 0px;
	position: relative;
	margin-top: 5px;
}
.zcwf_lblLeft .zcwf_privacy{padding: 6px;}
.zcwf_lblLeft .wfrm_fld_dpNn{display: none;}
.dIB{display: inline-block;}
.zcwf_lblLeft .zcwf_col_fld_slt {
	width: 60%;
	border: 1px solid #ccc;
	background: #fff;
	border-radius: 4px;
	font-size: 12px;
	float: left;
	resize: vertical;
	padding: 2px 5px;
}
.zcwf_lblLeft .zcwf_row:after, .zcwf_lblLeft .zcwf_col_fld:after {
	content: '';
	display: table;
	clear: both;
}
.zcwf_lblLeft .zcwf_col_help {
	float: left;
	margin-left: 7px;
	font-size: 12px;
	max-width: 35%;
	word-break: break-word;
}
.zcwf_lblLeft .zcwf_help_icon {
	cursor: pointer;
	width: 16px;
	height: 16px;
	display: inline-block;
	background: #fff;
	border: 1px solid #c0c6cc;
	color: #c1c1c1;
	text-align: center;
	font-size: 11px;
	line-height: 16px;
	font-weight: bold;
	border-radius: 50%;
}
.zcwf_lblLeft .zcwf_row {margin: 15px 0px;}
.zcwf_lblLeft .formsubmit {
	margin-right: 5px;
	cursor: pointer;
	color: var(--baseColor);
	font-size: 12px;
}
.zcwf_lblLeft .zcwf_privacy_txt {
	width: 90%;
	color: rgb(0, 0, 0);
	font-size: 12px;
	font-family: Arial;
	display: inline-block;
	vertical-align: top;
	color: var(--baseColor);
	padding-top: 2px;
	margin-left: 6px;
}
.zcwf_lblLeft .zcwf_button {
	font-size: 12px;
	color: var(--baseColor);
	border: 1px solid #c0c6cc;
	padding: 3px 9px;
	border-radius: 4px;
	cursor: pointer;
	max-width: 120px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.zcwf_lblLeft .zcwf_tooltip_over{
	position: relative;
}
.zcwf_lblLeft .zcwf_tooltip_ctn{
	position: absolute;
	background: #dedede;
	padding: 3px 6px;
	top: 3px;
	border-radius: 4px;word-break: break-word;
	min-width: 100px;
	max-width: 150px;
	color: var(--baseColor);
	z-index: 100;
}
.zcwf_lblLeft .zcwf_ckbox{
	float: left;
}
.zcwf_lblLeft .zcwf_file{
	width: 55%;
	box-sizing: border-box;
	float: left;
}
.clearB:after{
	content:'';
	display: block;
	clear: both;
}
@media all and (max-width: 600px) {
	.zcwf_lblLeft .zcwf_col_lab, .zcwf_lblLeft .zcwf_col_fld {
		width: auto;
		float: none !important;
	}
	.zcwf_lblLeft .zcwf_col_help {width: 40%;}
}
</style>
<div class='zcwf_title' style='max-width: 600px;color: black; font-family:Arial;display:none;'>Webform Existing Customer</div>
<div class='zcwf_row wfrm_fld_dpNn'>
	<div class='zcwf_col_lab' style='font-size:12px; font-family: Arial;'>
		<label for='CONTACTCF5'>Source</label>
	</div>
	<div class='zcwf_col_fld'>
		<input type='text' id='CONTACTCF5' name='CONTACTCF5' maxlength='255' value='Existing&#x20;Customer'></input>
		<div class='zcwf_col_help'></div>
	</div>
</div>
<div class='zcwf_row'>
	<div class='zcwf_col_lab' style='font-size:12px; font-family: Arial;'>
		<label for='First_Name'>First Name<span style='color:red;'>*</span></label>
	</div>
	<div class='zcwf_col_fld'>
		<input type='text' id='First_Name' name='First Name' maxlength='40' pattern="[A-Za-z\s]+$" required></input>
		<div class='zcwf_col_help'></div>
	</div>
</div>
<div class='zcwf_row'>
	<div class='zcwf_col_lab' style='font-size:12px; font-family: Arial;'>
		<label for='Last_Name'>Last Name<span style='color:red;'>*</span></label>
	</div>
	<div class='zcwf_col_fld'>
		<input type='text' id='Last_Name' name='Last Name' maxlength='80' pattern="[A-Za-z\s]+$" required></input>
		<div class='zcwf_col_help'></div>
	</div>
</div>
<div class='zcwf_row'>
	<div class='zcwf_col_lab' style='font-size:12px; font-family: Arial;'>
		<label for='Email'>Email<span style='color:red;'>*</span></label>
	</div>
	<div class='zcwf_col_fld'>
		<input type='text' ftype='email' autocomplete='false' id='Email' name='Email' maxlength='100' required></input>
		<div class='zcwf_col_help'></div>
	</div>
</div>
<div class='zcwf_row'>
	<div class='zcwf_col_lab' style='font-size:12px; font-family: Arial;'>
		<label for='Phone'>Phone<span style='color:red;'>*</span></label>
	</div>
	<div class='zcwf_col_fld'>
		<input type='text' id='Phone' name='Phone' maxlength='50' pattern="[0-9]{10}" required></input>
		<div class='zcwf_col_help'></div>
	</div>
</div>
<div class='zcwf_row'>
	<div class='zcwf_col_lab' style='font-size:12px; font-family: Arial;'>
		<label for='Mailing_Zip'>Zip<span style='color:red;'>*</span></label>
	</div>
	<div class='zcwf_col_fld'>
		<input type='text' id='Mailing_Zip' name='Mailing Zip' maxlength='30' pattern="[0-9]+$" required></input>
		<div class='zcwf_col_help'></div>
	</div>
</div>
<div class='zcwf_row'>
	<div class='zcwf_col_lab' style='font-size:12px; font-family: Arial;'>
		<label for='Mailing_State'>State<span style='color:red;'>*</span></label>
	</div>
	<div class='zcwf_col_fld'>
		<input type='text' id='Mailing_State' name='Mailing State' maxlength='100'></input>
		<div class='zcwf_col_help'></div>
	</div>
</div>
<div class='zcwf_row'>
	<div class='zcwf_col_lab' style='font-size:12px; font-family: Arial;'>
		<label for='Mailing_City'>City<span style='color:red;'>*</span></label>
	</div>
	<div class='zcwf_col_fld'>
		<input type='text' id='Mailing_City' name='Mailing City' maxlength='100' required></input>
		<div class='zcwf_col_help'></div>
	</div>
</div>
<div class='zcwf_row'>
	<div class='zcwf_col_lab' style='font-size:12px; font-family: Arial;'>
		<label for='CONTACTCF4'>Message<span style='color:red;'>*</span></label>
	</div>
	<div class='zcwf_col_fld'>
		<input type='text' id='CONTACTCF4' name='CONTACTCF4' maxlength='255' required></input>
		<div class='zcwf_col_help'></div>
	</div>
</div>
<div class='zcwf_row'>
	<div class='zcwf_col_lab'></div>
	<div class='zcwf_col_fld'>
		<input type='submit' id='formsubmit' class='formsubmit zcwf_button' value='Submit' title='Submit'>
		<input type='reset' class='zcwf_button' name='reset' value='Reset' title='Reset'>
	</div>
</div>
	<script>
	function validateEmail4501926000086354115()
	{
		var form = document.forms['WebToContacts4501926000086354115'];
		var emailFld = form.querySelectorAll('[ftype=email]');
		var i;
		for (i = 0; i < emailFld.length; i++)
		{
			var emailVal = emailFld[i].value;
			if((emailVal.replace(/^\s+|\s+$/g, '')).length!=0 )
			{
				var atpos=emailVal.indexOf('@');
				var dotpos=emailVal.lastIndexOf('.');
				if (atpos<1 || dotpos<atpos+2 || dotpos+2>=emailVal.length)
				{
					alert('Please enter a valid email address. ');
					emailFld[i].focus();
					return false;
				}
			}
		}
		return true;
	}

 	  function checkMandatory4501926000086354115() {
		var mndFileds = new Array('First Name','Last Name','Email','Phone','Mailing City','Mailing State','Mailing Zip','CONTACTCF4');
		var fldLangVal = new Array('First\x20Name','Last\x20Name','Email','Phone','City','State','Zip','Message');
		for(i=0;i<mndFileds.length;i++) {
		  var fieldObj=document.forms['WebToContacts4501926000086354115'][mndFileds[i]];
		  if(fieldObj) {
			if (((fieldObj.value).replace(/^\s+|\s+$/g, '')).length==0) {
			 if(fieldObj.type =='file')
				{ 
				 alert('Please select a file to upload.'); 
				 fieldObj.focus(); 
				 return false;
				} 
			alert(fldLangVal[i] +' cannot be empty.'); 
   	   	  	  fieldObj.focus();
   	   	  	  return false;
			}  else if(fieldObj.nodeName=='SELECT') {
  	   	   	 if(fieldObj.options[fieldObj.selectedIndex].value=='-None-') {
				alert(fldLangVal[i] +' cannot be none.'); 
				fieldObj.focus();
				return false;
			   }
			} else if(fieldObj.type =='checkbox'){
 	 	 	 if(fieldObj.checked == false){
				alert('Please accept  '+fldLangVal[i]);
				fieldObj.focus();
				return false;
			   } 
			 } 
			 try {
			     if(fieldObj.name == 'Last Name') {
				name = fieldObj.value;
 	 	 	    }
			} catch (e) {}
		    }
		}
		if(!validateEmail4501926000086354115()){return false;}
		document.querySelector('.crmWebToEntityForm .formsubmit').setAttribute('disabled', true);
	}

function tooltipShow4501926000086354115(el){
	var tooltip = el.nextElementSibling;
	var tooltipDisplay = tooltip.style.display;
	if(tooltipDisplay == 'none'){
		var allTooltip = document.getElementsByClassName('zcwf_tooltip_over');
		for(i=0; i<allTooltip.length; i++){
			allTooltip[i].style.display='none';
		}
		tooltip.style.display = 'block';
	}else{
		tooltip.style.display='none';
	}
}
</script>
	</form>
</div>`}} />
  )
}

export default CustomerForm