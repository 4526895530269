import React, { useState } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { Section, SectionPageTitle, BreadCrumb } from "../components/Section"
import { MapIcon, PhoneLineIcon, EmailIcon } from "../components/Icons"
import CallDesignBuilding from "../components/CallDesignBuilding"
import GetInTouchContactForm from "../components/GetInTouchContactForm"
import BreakpointUp from "../components/Media/BreakpointUp"
import { StaticImage } from "gatsby-plugin-image"
import CustomerForm from "../components/CustomerForm"

const PageTitle = styled(SectionPageTitle)`
  height: 120px;
`

const BackgroundImage = styled.div`
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`
const BannerBottomCaption = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  width: 100%;
  box-shadow: 0 10px 45px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid #ebedf3;
  padding: 0;
  ${BreakpointUp.xl`
        padding:0 30px;
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;    
        margin-top: -100px;
    `}
`
const Address = styled.address`
  margin-bottom: 0;
  font-style: inherit;
  padding: 30px 20px;
  position: relative;
  width: 100%;
  ${BreakpointUp.md`       
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    `}
  ${BreakpointUp.lg`    
        padding:50px 20px;
    `}
    > strong {
    display: block;
    color: #0b619b;
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;
    ${BreakpointUp.lg`    
            font-size:28px;
            line-height:38px;
            font-weight:900;
        `}
  }
  + address {
    border-top: 1px solid #ebedf3;
    ${BreakpointUp.md`           
            border-top:none;
            border-left:1px solid #EBEDF3;
        `}
  }
`
const ListInfo = styled.ul`
  margin: 0;
  padding: 0;
  ${BreakpointUp.lg`            
        font-size:18px;
        font-weight:700;
    `}
`
const ListInfoItem = styled.li`
  margin: 0;
  padding: 0;
  + li {
    margin-top: 15px;
  }
  .label {
    color: #595959;
    font-weight: 500;
    min-width: 80px;
    display: block;
    font-size: 14px;
    line-height: 18px;
    ${BreakpointUp.sm`        
            font-size:16px;
            line-height:20px;
            display: inline-block;
        `}
  }
  strong {
    color: #000;
  }
  a {
    color: #000;
    font-weight: 700;
    &:hover {
      color: #0b619b;
    }
    + span {
      margin-left: 10px;
    }
  }
`
const BadgeStatic = styled.span`
  background-color: #ebeef6;
  color: #444;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  border-radius: 12px;
  padding: 4px 14px;
  line-height: 18px;
`

const BannerTopCaption = styled.div`
  ${BreakpointUp.lg`
        width: 100%;
        height: 100%;
        position: absolute;
        top:0;
        left:0;
        .container{
            height: 100%;
        }
    `}
`
const BannerTopBoby = styled.div`
  height: 260px;
  width: auto;
  background-color: #0b619b;
  box-shadow: 0px 0px 0px 10px rgba(255, 255, 255, 0.1);

  ${BreakpointUp.lg`            
    position: absolute;
    z-index: 1;    
    right:0;
    top: calc(50% - 130px);
    width: 400px;
  `}
  address {
    strong {
      color: #fff;
    }
    .icon {
      svg {
        fill: #fff;
      }
      + .text {
        margin-left: 20px;
      }
    }
    .text {
      color: #fff;
    }
  }
`
const FormTab = styled.div`
  display: flex;
  align-items: center;
  border-radius: 30px;
  
  border: 1px solid #ddd;
  width: 400px;
  margin: 20px auto 60px;
`
const TabItem = styled.button`
  border-radius: 30px;
  width: 200px;
  padding: 10px 20px;
  text-align: center;
  outline: none;
  border: 0;

  &.active {
    border: 1px solid #FC0002;
    color: #AD0000;
    background-color: rgba(252, 0, 2, 0.1);
  }
`
const ContactUs = ({ data, location }) => {
  const [formTab, setFormTab] = useState("newcustomer")
  return (
    <Layout location={location}>
      <Seo title="Metal Carport & Steel Buliding Installer" description="Contact Coast To Coast: your meta carport and steel building installer of choice. Call us at (866) 681-7846 or send us a message on our website here." />
      <BreadCrumb className="white">
        <div className="container">
          <Link to="/">Home / </Link>
          <Link to="/about-us">About Us / </Link>
          <span>Contact Us</span>
        </div>
      </BreadCrumb>
      <Section pt="0" pb="0" xpt="30px" xpb="0" bgColor="#fff">
        <BackgroundImage>
          <StaticImage src="../images/contact-banner.jpg" alt="contact-banner" />
        </BackgroundImage>
        <BannerTopCaption>
          <div className="container">
            <BannerTopBoby>
              <Address>
                <strong>Coast To Coast Carports</strong>
                <ListInfo>
                  <ListInfoItem>
                    <span className="icon">
                      <MapIcon />
                    </span>
                    <span className="text">22525 I-40 Knoxville, AR 72845</span>
                  </ListInfoItem>
                  <ListInfoItem>
                    <span className="icon">
                      <PhoneLineIcon />
                    </span>
                    <a className="text" href="tel:8666817846" aria-label="phone number">
                      (866) 681-7846
                    </a>
                  </ListInfoItem>
                  <ListInfoItem>
                    <span className="icon">
                      <EmailIcon />
                    </span>
                    <a
                      className="text"
                      href="mailto:info@coast-to-coastcarports.com"
                    >
                      <u>info@coast-to-coastcarports.com</u>
                    </a>
                  </ListInfoItem>
                </ListInfo>
              </Address>
            </BannerTopBoby>
          </div>
        </BannerTopCaption>
        <div className="container">
          <BannerBottomCaption>
            <Address>
              <strong>SALES DEPARTMENT</strong>
              <ListInfo>
                <ListInfoItem>
                  <div className="label">Toll Free :</div>
                  <a href="tel:8666817846" aria-label="(866) 681-7846">(866) 681-7846</a>{" "}
                  <BadgeStatic>New Customers</BadgeStatic>
                </ListInfoItem>
                <ListInfoItem>
                  <div className="label">Fax :</div>
                  <strong>479-885-2977</strong>
                </ListInfoItem>
                <ListInfoItem>
                  <div className="label">Email :</div>
                  <a href="mailto:sales@coast-to-coastcarports.com">
                    <u>sales@coast-to-coastcarports.com</u>
                  </a>
                </ListInfoItem>
              </ListInfo>
            </Address>
            <Address>
              <strong>CUSTOMER SERVICE</strong>
              <ListInfo>
                <ListInfoItem>
                  <div className="label">Toll Free :</div>
                  <a href="tel:8662673790" aria-label="phone number">(866) 267-3790</a>{" "}
                  <BadgeStatic>Existing Customers</BadgeStatic>
                </ListInfoItem>
                <ListInfoItem>
                  <div className="label">Fax :</div>
                  <strong>877-472-5097</strong>
                </ListInfoItem>
                <ListInfoItem>
                  <div className="label">Email :</div>
                  <a href="mailto:customerservice@coast-to-coastcarports.com">
                    <u>customerservice@coast-to-coastcarports.com</u>
                  </a>
                </ListInfoItem>
              </ListInfo>
            </Address>
          </BannerBottomCaption>
        </div>
      </Section>
      <Section
        pt="250px"
        pb="120px"
        xpt="60px"
        xpb="60px"
        bgColor="#F2F4F9"
        className="circle-right md"
      >
        <div className="container mw-1180">
          <PageTitle maxWidth="725px" mb="50px">Get In Touch & Let Us Know How We Can Help!</PageTitle>
          <FormTab>
            <TabItem className={formTab === "newcustomer" ? "active": ""} onClick={()=>setFormTab("newcustomer")}>New Customers</TabItem>
            <TabItem className={formTab === "existingcustomer" ? "active": ""} onClick={()=>setFormTab("existingcustomer")}>Existing Customers</TabItem>
          </FormTab>
          {formTab === "newcustomer" ? (
            <GetInTouchContactForm states={data.allContentfulState.edges} location={location} />
          ):(
            <CustomerForm />
          )}
          
        </div>
      </Section>
      <CallDesignBuilding />
    </Layout>
  )
}

export default ContactUs

export const pageQuery = graphql`
  query ContactUsPageQuery {
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
        }
      }
    }
  }
`
